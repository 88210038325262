.header {

}
.logo {
  margin: -58px 0 0 -100px;
  @include position(absolute, 50% 0 0 50%);
  img {

  }
}
