.nav {
  ul {
    list-style: none;
    li {
      a {

      }
    }
  }
}
